import React, { Children, useEffect, useState } from "react";

const Container=(props: any)=> {
  /* Settings */
  const heightOffset = 73;
  const vh = window.innerHeight;
  const vw = window.innerWidth;
  return (<div           style={{
    marginTop: `${heightOffset}px`,
    width: `${vw}px`,
    height: `${vh - heightOffset}px`,
    justifyContent: 'center',
    display:'flex'
  }}>
    {props.children}
  </div>)
}

export default Container;