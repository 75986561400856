import { Container } from "@mui/system";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import Header from "../Header/Header";

function People() {
    return (<Header>
        <Container>
            
        </Container>
    </Header>)
}

export default People;